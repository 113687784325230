<template>
  <div id="app" ref="container">
    <!-- <div class="audio" v-if="showAudio">
      <aplayer 
        autoplay 
        mini 
        float
        :music="{ src: 'duoxingyun.mp3' }"
      />
    </div> -->
    <!-- <ul class="list">
      <li v-for="(img, index) in list" :key="index">
        <img style="width: 50px; height: 50px" :src="img" @click="toggle(img, index)">
      </li>
    </ul> -->
  </div>
</template>

<script>
import { Viewer } from 'photo-sphere-viewer';
// import * as adapter from 'photo-sphere-viewer/dist/adapters/equirectangular-video.js';
// import * as plugin from 'photo-sphere-viewer/dist/plugins/video.js';

import 'photo-sphere-viewer/dist/photo-sphere-viewer.css';
// import 'photo-sphere-viewer/dist/plugins/video.css';
// import Aplayer from 'vue-aplayer'

export default {
  // components: {
  //   Aplayer
  // },
  data() {
    return {
      // list: [
      //   'pano_7000.jpg',
      //   'test.png',
      //   'aaa.jpg'
      // ],
      // index: 0,
      // showAudio: false,
      viewer: null
    }
  },
  mounted() {
    const { imgSrc } = this.$route.query

    // 图片全景预览
    this.viewer = new Viewer({
      container: this.$refs.container,
      panorama: imgSrc
    });

    // 播放视频
    // this.viewer = new Viewer({
    //   container: this.$refs.app,
    //   adapter: [adapter.EquirectangularVideoAdapter, {
    //     autoplay: true, // default
    //     muted: false, // default
    //   }],
    //   panorama: {
    //     source: 'https://mudin.github.io/vue-vr/assets/ClashofClans.mp4', // also supports webm
    //   },
    //   plugins: [
    //     plugin.VideoPlugin
    //   ]
    // });

    this.destroyViewer()

    // this.viewer.once('ready', () => {
    //   this.showAudio = true
    // });
  },
  methods:{
    destroyViewer() {
      this.$once('hook:destroyed', () => {
        this.viewer.destroy()
      })
    },
    // toggle(img, index) {
    //   if (index === this.index) return

    //   this.index = index
    //   this.viewer.setPanorama(img)
    // }
  }
}
</script>

<style>
  html, body, #app{
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  /* .audio{
    position: absolute;
    right: 10px;
    top: 30px;
    z-index: 999;
  }
  .list{
    position: fixed;
    left: 10px;
    bottom: 55px;
    z-index: 999;
    margin: 0;
    padding: 0;
    list-style: none;
    width: 51px;
    height: 150px;
    border: 1px solid yellow;
    border-radius: 3px;
  }
  img{
    vertical-align: top;
    cursor: pointer;
  } */
</style>
